import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import SideBar from "../../../components/video-tutorial/VideoNavSideBar";
import SelfLearningVideoContent from "../../../components/SelfLearningVideos/SelfLearningVideoContent";
import CustomNavbar from "../../../components/CustomNavbar";
import {
  descriptions,
  VideoMenus,
} from "../../../components/SelfLearningVideos/SelfLearningVideosList";

function SelfLearningSample() {
  const { id } = useParams();

  const [videoDescription, setVideoDescription] = useState(null);

  useEffect(() => {
    const selectedVideo = descriptions.find(
      (videoPath) => videoPath.slugPath === id
    );

    setVideoDescription(selectedVideo || null);
  }, [id]);

  return (
    <div className="body_wrapper">
      <CustomNavbar
        mClass="menu_eight"
        nClass="w_menu"
        slogo="sticky_logo"
        blueArrow="blue_arrow"
        hbtnClass="security_btn"
        headerClass="position"
        productLogo="fireFlinkAcadamy"
      />
      <section>
        <div className="container mt_40 mb-30">
          <h2 className="f_size_25 f_600 prodcolor f_p text-center">
            Self Learning Videos
          </h2>
          <div className="video_left_layout mt_20">
            <div className="row">
              <div className="col-lg-3 p20 mt_20 mb_70">
                <SideBar menus={VideoMenus} />
              </div>
              <div className="col-lg-9 right_line overflow-hidden">
                {videoDescription?.videoId ? (
                  <SelfLearningVideoContent
                    title={videoDescription?.title}
                    videoId={videoDescription?.videoId}
                  />
                ) : (
                  <h3 className="f_size_20 f_600 prodcolor f_p mt_20 text-center">
                    Video not found
                  </h3>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SelfLearningSample;
