export const descriptions = [
  {
    slugPath: "registration",
    title: "Registration",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/ProjectCreationandManuaTestCaseManagament/1.Registration.mp4",
  },
  {
    slugPath: "project-creation",
    title: "Project Creation",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/ProjectCreationandManuaTestCaseManagament/2.+Create+a+Simple+Project+and+Project+levels.mp4",
  },
  {
    slugPath: "project-managment",
    title: "Project Managment",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/ProjectCreationandManuaTestCaseManagament/3.+Project+Managment.mp4",
  },
  {
    slugPath: "user-managment",
    title: "User Managment",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/ProjectCreationandManuaTestCaseManagament/4.User+Management.mp4",
  },
  {
    slugPath: "roles-managment",
    title: "Roles Managment",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/ProjectCreationandManuaTestCaseManagament/5.+Roles+Management.mp4",
  },
  {
    slugPath: "test-development",
    title: "Test Development",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/ProjectCreationandManuaTestCaseManagament/6.+Tree+Structure+of+Test+development.mp4",
  },
  {
    slugPath: "manual-test-case-management",
    title: "Test Case Management P-1",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/ProjectCreationandManuaTestCaseManagament/7.1+Manual+test+case+Managemnet+Part-1.mp4",
  },
  {
    slugPath: "manual-test-case-management-part2",
    title: "Test Case Management P-2",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/ProjectCreationandManuaTestCaseManagament/7.2+Manual+test+case+Managemnet+Part-2.mp4",
  },
  {
    slugPath: "import-export-part1",
    title: "TC Import/Export Part 1",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/ProjectCreationandManuaTestCaseManagament/8.1+Import+and+Export+feature+of+FireFlink+Part-1.mp4",
  },
  {
    slugPath: "import-export-part2",
    title: "TC Import/Export Part 2",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/ProjectCreationandManuaTestCaseManagament/8.2+Import+and+Export+feature+of+FireFlink+Part-2.mp4",
  },
  {
    slugPath: "manual-suite-execution",
    title: "Manual Suite Execution",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/ProjectCreationandManuaTestCaseManagament/9.+Manual+Suite+Execution.mp4",
  },
  {
    slugPath: "manual-suite-analytics",
    title: "Manual Suite Analytics",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/ProjectCreationandManuaTestCaseManagament/10.+Manual+Suite+Analytics.mp4",
  },
  {
    slugPath: "introduction-to-scriptless-automation",
    title: "Introduction to Scriptless automation",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/11.+Introduction+to+Scriptless+automation.mp4",
  },
  {
    slugPath: "script-creation-part1",
    title: "Simple Script creation Part1",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/12.+Create+a+Simple+Script+Part-1.mp4",
  },
  {
    slugPath: "script-creation-part2",
    title: "Simple Script creation Part2",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/13.+Create+a+Simple+Script+Part-2.mp4",
  },
  {
    slugPath: "locators-part1",
    title: "Locators Part1",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/14.+Locators+Part-1.mp4",
  },
  {
    slugPath: "locators-part2",
    title: "Locators Part2",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/15.+Locators+Part-2.mp4",
  },
  {
    slugPath: "element-respository",
    title: "Element Repository",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/16.+Element+Repository(Manual).mp4",
  },
  {
    slugPath: "shared-elements",
    title: "Shared Elements",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/18.+Shared+Elements.mp4",
  },
  {
    slugPath: "reference-element",
    title: "Reference Element",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/19.+Reference+Element+with+Dynamic+Locator.mp4",
  },
  {
    slugPath: "dynamic-locator",
    title: "Dynamic Locator",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/20.+Handling+Multiple+Static+Element+with+Single+dynamic+locator.mp4",
  },
  {
    slugPath: "variables",
    title: "Variables",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/21.+Variables.mp4",
  },
  {
    slugPath: "different-nlp",
    title: "Different NLP's",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/22.+Different+NLP's.mp4",
  },
  {
    slugPath: "if-failed-checkpoints",
    title: "If Failed CheckPoints",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/23.+If+Failed+CheckPoints.mp4",
  },
  {
    slugPath: "synchronization",
    title: "Synchronization",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/24.+Synchronization.mp4",
  },
  {
    slugPath: "qucik-run-settings",
    title: "Quick Run Settings",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/25.+Quick+Run+Settings.mp4",
  },
  {
    slugPath: "status-of-execution",
    title: "Status of Execution",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/26.+Status+of+Execution.mp4",
  },
  {
    slugPath: "script-debugging",
    title: "Script Debugging",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/27.+Script+Debugging.mp4",
  },
  {
    slugPath: "step-group",
    title: "Step Group",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/28.+Step+Group.mp4",
  },
  {
    slugPath: "parameterized-sg",
    title: "Parameterized SG",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/29.+Parameterized+SG.mp4",
  },
  {
    slugPath: "levels-of-execution",
    title: "Levels of Execution",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/30.+Levels+of+Execution.mp4",
  },
  {
    slugPath: "pre-post-condition-part1",
    title: "Pre & Post condition Part1",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/31.+Script+level+Pre+and+Post+condition.mp4",
  },
  {
    slugPath: "pre-post-condition-part2",
    title: "Pre & Post condition Part2",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/32.+Module+level+Pre+and+Post.mp4",
  },
  {
    slugPath: "depends-on-script",
    title: "Depends on script",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/33.+Depends+on+script.mp4",
  },
  {
    slugPath: "test-data-excel",
    title: "Test Data-Excel",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/34.+Test+Data-Excel.mp4",
  },
  {
    slugPath: "test-data-write-excel",
    title: "Test Data - Write Excel",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/35.+Test+Data+-+Write+Excel.mp4",
  },
  {
    slugPath: "single-row-table-excel-nlp",
    title: "Single Row Table Excel NLP",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/36.Single+Row+Table+Excel+NLP.mp4",
  },
  {
    slugPath: "multi-row-table-excel-nlp",
    title: "Multi Row Table Excel NLP",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/37.Multi+Row+Table+Excel+NLP.mp4",
  },
  {
    slugPath: "data-provider",
    title: "Data Provider",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/38.+Data+Provider.mp4",
  },
  {
    slugPath: "suite-creation-and-execution",
    title: "Suite Creation and Execution",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/39.Suite+Creation+and+Execution.mp4",
  },
  {
    slugPath: "variable-set-in-suite",
    title: "Variable set in Suite",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/40.Variable+set+in+Suite.mp4",
  },
  {
    slugPath: "parallel-run",
    title: "Parallel Run",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/41.+Parallel+Run.mp4",
  },
  {
    slugPath: "modes-of-suite-execution",
    title: "Modes of Suite Execution",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/42.Modes+of+Suite+Execution.mp4",
  },
  {
    slugPath: "suite-access-and-auto-run-failed-scripts",
    title: "Suite Access & AutoRun Failed Scripts",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/43.+Suite+Access+and+AutoRun+Failed+Scripts.mp4",
  },
  {
    slugPath: "tagging-part1",
    title: "Tagging Part1",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/44.+Tagging+(Theory).mp4",
  },
  {
    slugPath: "tagging-part2",
    title: "Tagging Part2",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/45.+Tagging.mp4",
  },
  {
    slugPath: "analytics",
    title: "Analytics",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/46.+Analytics.mp4",
  },
  {
    slugPath: "defect-tracking",
    title: "Defect Tracking",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/47.+Defect+Tracking.mp4",
  },
  {
    slugPath: "dashboard",
    title: "Dashboard",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/48.+Dashboard.mp4",
  },
  {
    slugPath: "configuration-part1",
    title: "Configuration Part1",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/49.+Run+configuration+in+Project+level.mp4",
  },
  {
    slugPath: "configuration-part2",
    title: "Configuration Part2",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/50.+Configurations.mp4",
  },
  {
    slugPath: "cloud-platform-integrations",
    title: "Cloud Platform Integrations",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/51.+Cloud+Platform+Integrations.mp4",
  },
  {
    slugPath: "jira-integration",
    title: "Jira Integration",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/52.+Jira+Integration.mp4",
  },
  {
    slugPath: "jenkins-iIntegration",
    title: "Jenkins Integration",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/53.+Jenkins+Integration.mp4",
  },
  {
    slugPath: "azure-integration",
    title: "Azure Integration",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/webautomation/54.+Azure+Integration.mp4",
  },
  {
    slugPath: "android-automation-prerequisite",
    title: "Android Automation Prerequisite",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/Mobile_Android/1.+Android+Automation+Prerequisite.mp4",
  },
  {
    slugPath: "android-automation-part1",
    title: "Android Automation Part1",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/Mobile_Android/2.+Android+Automation+Part-1.mp4",
  },
  {
    slugPath: "android-automation-part2",
    title: "Android Automation Part2",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/Mobile_Android/3.+Android+Automation+Part-2.mp4",
  },
  {
    slugPath: "elements-handling-part1",
    title: "Elements Handling Part1",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/Mobile_Android/4.+Android+Elements+Handling+Part-1.mp4",
  },
  {
    slugPath: "elements-handling-part2",
    title: "Elements Handling Part2",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/Mobile_Android/5.+Android+Elements+Handling+Part-2.mp4",
  },
  {
    slugPath: "mobile-web-automation",
    title: "Mobile Web Automation",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/Mobile_Android/6.+Mobile+Web+Automation.mp4",
  },
  {
    slugPath: "ios-automation-setup",
    title: "iOS automation setup",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/Mobile_ios/1.+iOS+automation+setup-.mp4",
  },
  {
    slugPath: "ios-automation",
    title: "iOS automation",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/Mobile_ios/2.+iOS+automation+using+FireFlink.mp4",
  },
  {
    slugPath: "api-testing",
    title: "API Testing",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/Mobile_ios/01.+API+Testing.mp4",
  },
  {
    slugPath: "ssmc-execution",
    title: "SSMC Execution",
    videoId:
      "https://fireflinktrainning-videos.s3.us-west-1.amazonaws.com/SSMC/01.+SSMC+Execution.mp4",
  },
];

export const VideoMenus = [
  {
    label: "Sign-Up and Set-Up",

    submenu: [
      {
        label1: "Registration",
        path: "/fireflink-academy/self-learning-video/registration",
      },
      {
        label1: "Project Creation",
        path: "/fireflink-academy/self-learning-video/project-creation",
      },
      {
        label1: "Project Managment",
        path: "/fireflink-academy/self-learning-video/project-managment",
      },
      {
        label1: "User Managment",
        path: "/fireflink-academy/self-learning-video/user-managment",
      },
      {
        label1: "Roles Managment",
        path: "/fireflink-academy/self-learning-video/roles-managment",
      },
    ],
  },
  {
    label: "Test Case Management",

    submenu: [
      {
        label1: "Module Structure",
        path: "/fireflink-academy/self-learning-video/test-development",
      },
      {
        label1: "Test Case Management P-1",
        path:
          "/fireflink-academy/self-learning-video/manual-test-case-management",
      },
      {
        label1: "Test Case Management P-2",
        path:
          "/fireflink-academy/self-learning-video/manual-test-case-management-part2",
      },
      {
        label1: "TC Import/Export P-1",
        path: "/fireflink-academy/self-learning-video/import-export-part1",
      },
      {
        label1: "TC Import/Export  P-2",
        path: "/fireflink-academy/self-learning-video/import-export-part2",
      },
      {
        label1: "Manual Suite Execution",
        path: "/fireflink-academy/self-learning-video/manual-suite-execution",
      },
      {
        label1: "Manual Suite Analytics",
        path: "/fireflink-academy/self-learning-video/manual-suite-analytics",
      },
      {
        label1: "Practice Test - 1",
        path: "https://forms.office.com/r/Vp4fv8B1tZ",
      },
    ],
  },
  {
    label: "Web Automation",
    submenu: [
      {
        label1: "Introduction to Scriptless automation",
        path:
          "/fireflink-academy/self-learning-video/introduction-to-scriptless-automation",
      },
      {
        label1: "Simple Script creation P-1",
        path: "/fireflink-academy/self-learning-video/script-creation-part1",
      },
      {
        label1: "Simple Script creation P-2",
        path: "/fireflink-academy/self-learning-video/script-creation-part2",
      },
      {
        label1: "Locators P-1",
        path: "/fireflink-academy/self-learning-video/locators-part1",
      },
      {
        label1: "Locators P-2",
        path: "/fireflink-academy/self-learning-video/locators-part2",
      },
      {
        label1: "Element Repository",
        path: "/fireflink-academy/self-learning-video/element-respository",
      },
      {
        label1: "Shared Elements",
        path: "/fireflink-academy/self-learning-video/shared-elements",
      },
      {
        label1: "Reference Element ",
        path: "/fireflink-academy/self-learning-video/reference-element",
      },
      {
        label1: "Dynamic Locator",
        path: "/fireflink-academy/self-learning-video/dynamic-locator",
      },
      {
        label1: "Variables",
        path: "/fireflink-academy/self-learning-video/variables",
      },
      {
        label1: "Different NLP's",
        path: "/fireflink-academy/self-learning-video/different-nlp",
      },
      {
        label1: "If Failed CheckPoints",
        path: "/fireflink-academy/self-learning-video/if-failed-checkpoints",
      },
      {
        label1: "Practice Test - 2",
        path: "https://forms.office.com/r/s5ufRHRsKH",
      },
      {
        label1: "Synchronization",
        path: "/fireflink-academy/self-learning-video/synchronization",
      },
      {
        label1: "Quick Run Settings",
        path: "/fireflink-academy/self-learning-video/qucik-run-settings",
      },
      {
        label1: "Status of Execution",
        path: "/fireflink-academy/self-learning-video/status-of-execution",
      },
      {
        label1: "Script Debugging",
        path: "/fireflink-academy/self-learning-video/script-debugging",
      },
      {
        label1: "Step Group",
        path: "/fireflink-academy/self-learning-video/step-group",
      },
      {
        label1: "Parameterized SG",
        path: "/fireflink-academy/self-learning-video/parameterized-sg",
      },
      {
        label1: "Levels of Execution",
        path: "/fireflink-academy/self-learning-video/levels-of-execution",
      },
      {
        label1: "Pre & Post condition P-1",
        path: "/fireflink-academy/self-learning-video/pre-post-condition-part1",
      },
      {
        label1: "Pre & Post condition P-2",
        path: "/fireflink-academy/self-learning-video/pre-post-condition-part2",
      },
      {
        label1: "Depends on script",
        path: "/fireflink-academy/self-learning-video/depends-on-script",
      },
      {
        label1: "Test Data-Excel",
        path: "/fireflink-academy/self-learning-video/test-data-excel",
      },
      {
        label1: "Test Data - Write Excel",
        path: "/fireflink-academy/self-learning-video/test-data-write-excel",
      },
      {
        label1: "Single Row Table Excel NLP",
        path:
          "/fireflink-academy/self-learning-video/single-row-table-excel-nlp",
      },
      {
        label1: "Multi Row Table Excel NLP",
        path:
          "/fireflink-academy/self-learning-video/multi-row-table-excel-nlp",
      },
      {
        label1: "Data Provider",
        path: "/fireflink-academy/self-learning-video/data-provider",
      },
      {
        label1: "Practice Test - 3",
        path: "https://forms.office.com/r/k6nEXuiwDg",
      },
      {
        label1: "Suite Creation and Execution",
        path:
          "/fireflink-academy/self-learning-video/suite-creation-and-execution",
      },
      {
        label1: "Variable set in Suite",
        path: "/fireflink-academy/self-learning-video/variable-set-in-suite",
      },
      {
        label1: "Parallel Run",
        path: "/fireflink-academy/self-learning-video/parallel-run",
      },
      {
        label1: "Modes of Suite Execution",
        path: "/fireflink-academy/self-learning-video/modes-of-suite-execution",
      },
      {
        label1: "Suite Access & AutoRun Failed Scripts",
        path:
          "/fireflink-academy/self-learning-video/suite-access-and-auto-run-failed-scripts",
      },
      {
        label1: "Tagging P-1",
        path: "/fireflink-academy/self-learning-video/tagging-part1",
      },
      {
        label1: "Tagging P-2",
        path: "/fireflink-academy/self-learning-video/tagging-part2",
      },
      {
        label1: "Analytics",
        path: "/fireflink-academy/self-learning-video/analytics",
      },
      {
        label1: "Defect Tracking",
        path: "/fireflink-academy/self-learning-video/defect-tracking",
      },
      {
        label1: "Dashboard",
        path: "/fireflink-academy/self-learning-video/dashboard",
      },
      {
        label1: "Configuration P-1",
        path: "/fireflink-academy/self-learning-video/configuration-part1",
      },
      {
        label1: "Configuration P-2",
        path: "/fireflink-academy/self-learning-video/configuration-part2",
      },
      {
        label1: "Cloud Platform Integrations",
        path:
          "/fireflink-academy/self-learning-video/cloud-platform-integrations",
      },
      {
        label1: "Jira Integration",
        path: "/fireflink-academy/self-learning-video/jira-integration",
      },
      {
        label1: "Jenkins Integration",
        path: "/fireflink-academy/self-learning-video/jenkins-iIntegration",
      },
      {
        label1: "Azure Integration",
        path: "/fireflink-academy/self-learning-video/azure-integration",
      },
      {
        label1: "Practice Test - 4",
        path: "https://forms.office.com/r/PmNDZ5DVJi",
      },
    ],
  },
  {
    label: "Mobile Automation",

    submenu: [
      {
        label1: "Android",
        submenu: [
          {
            label1: "Android Automation Prerequisite",
            path:
              "/fireflink-academy/self-learning-video/android-automation-prerequisite",
          },
          {
            label1: "Android Automation P-1",
            path:
              "/fireflink-academy/self-learning-video/android-automation-part1",
          },
          {
            label1: "Android Automation P-2",
            path:
              "/fireflink-academy/self-learning-video/android-automation-part2",
          },
          {
            label1: "Elements Handling P-1",
            path:
              "/fireflink-academy/self-learning-video/elements-handling-part1",
          },
          {
            label1: "Elements Handling P-2",
            path:
              "/fireflink-academy/self-learning-video/elements-handling-part2",
          },
          {
            label1: "Mobile Web Automation",
            path:
              "/fireflink-academy/self-learning-video/mobile-web-automation",
          },
          {
            label1: "Practice Test - 5",
            path: "https://forms.office.com/r/ZdPbzThSxh",
          },
        ],
      },
      {
        label1: "iOS",
        submenu: [
          {
            label1: "iOS automation setup",
            path: "/fireflink-academy/self-learning-video/ios-automation-setup",
          },
          {
            label1: "iOS automation",
            path: "/fireflink-academy/self-learning-video/ios-automation",
          },
        ],
      },
    ],
  },

  {
    label: "API Automation",
    submenu: [
      {
        label1: "API Automation",
        path: "/fireflink-academy/self-learning-video/api-testing",
      },
      {
        label: "Practice Test - 6",
        path: "https://forms.office.com/r/CQFwtpvmv7",
      },
    ],
  },

  {
    label: "SSMC Execution",
    path: "/fireflink-academy/self-learning-video/ssmc-execution",
  },
];
